import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import LunchContent from '../components/ContentPages/LunchContent';

function SaturdayLunchPage({ data, location }) {
  const sanityContent = data.saturdaylunchpage;

  return (
    <Layout location={location} lang="no">
      <LunchContent sanityContent={sanityContent} lang="No" />
    </Layout>
  );
}

export const query = graphql`
  query SaturdayLunchPageNo {
    saturdaylunchpage: sanityPage(id: { eq: "-7f5db90f-bc71-5a63-85bd-07bdb124f369" }) {
      id
      titleNo
      description {
        _rawTextNo
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
          menuSectionsTwo {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
        }
      }
    }
  }
`;

export default SaturdayLunchPage;
